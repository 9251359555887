import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../routes/routes-names';
import Dashboard from '../dashboard';
import Layout from '../layout';
import UserManagement from '../user-management';
import ReportedCompanies from '../company-list-old';
import CompanyList from '../company-list';
import CompanyView from '../company-list-old/company-view';
import CandidateList from '../candidate-list';
import ReportedCandidateList from '../candidate-list-old';
import CandidateView from '../candidate-list/candidate-view';
import CompanyVerification from '../company-verification';
import Policy from '../../containers/policy/policy';
import CompanyRegistration from '../company-registrations/companyRegister';
import CompanyRegistrationLanding from '../company-registrations/companyRegistrationLanding';
import CompanyVerificationLanding from '../company-verification/companyVerificationLanding';
import CandidateListLanding from '../candidate-list/candidateListLanding';
import CompanyListLanding from '../company-list/companyListLanding';
import HRMSCompanyListLanding from '../hrms-company-list/hrmsCompanyListLanding';

const Room = () => {
    return (
        <Layout>
            <Switch>
                <Route path={routes.dashboard} component={Dashboard} />
                <Route path={routes.reportedCompanyList} component={ReportedCompanies} exact />
                <Route path={routes.companyRegistration} component={CompanyRegistrationLanding} exact />
                <Route path={routes.companyVerification} component={CompanyVerificationLanding} exact />
                <Route path={routes.companyList} component={CompanyListLanding} exact />
                <Route path={routes.hrmsCompanyList} component={HRMSCompanyListLanding} exact />
                <Route path={routes.candidateList} component={CandidateListLanding} exact />
                <Route path={routes.reportedCandidateList} component={ReportedCandidateList} exact />
                <Route path={routes.userManagement} component={UserManagement} />
                <Route path={routes.CompanyView} component={CompanyView} />
                <Route path={routes.candidateView} component={CandidateView} />
                <Route path={routes.policy} component={Policy} />
            </Switch>
        </Layout>
    );
};

export default Room;
