import React, { useCallback, useEffect, useState } from 'react';
import { IResolveParams, LoginSocialMicrosoft } from 'reactjs-social-login';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SocialEnum, SocialEnumKeyType } from '../../types/auth';
import { authService } from '../../lib/api/auth';
import { goToRoom } from './utils';
import { microsoftClientId } from '../../config/constants';
import MediaLayout from './media-layout';
import microsoftLogo from '../../assets/img/Microsoft.png';

type Props = {
    invitationId?: string;
};

const AuthSocial: React.FC<Props> = ({ invitationId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState('');

    const signIn = useCallback(
        async (provider: SocialEnumKeyType, profile: any) => {
            setLoading(provider);
            const email = profile.userPrincipalName || profile.mail;
            if (invitationId) {
                try {
                    await authService.joinByInvitation({
                        email,
                        invitationId,
                    });
                } catch (err) {
                    console.log('error join by invitation', err);
                }
            }

            try {
                setLoading(provider);
                const data = await authService.socialSignIn({
                    Email: email,
                    AuthType: SocialEnum[provider],
                    Token: profile.id_token,
                });
                if (data) {
                    var firstName = profile.displayName.split(' ')[0];
                    var fullName = firstName + ' ' + profile.surname;
                    localStorage.setItem('AdminEmail', email);
                    localStorage.setItem('AdminName', fullName);
                    await goToRoom(data, dispatch);
                }
            } catch (err: any) {
                if (err.response.status === 401) {
                    toast.error('Your email is not registered in the system or you do not have access.');
                } else {
                    console.log('error socialSignIn', err);
                }
                setLoading('');
            }
        },
        [dispatch, invitationId]
    );

    return (
        <MediaLayout>
            <div className="w-100 pb-0 text-center">
                <h1 className="fw-700 fs-18">Sign in with</h1>
            </div>
            <div className="d-sm-flex d-lg-block d-xl-flex justify-content-center mb-3 social-btns">
                <div className="mx-3">
                    {loading === 'microsoft' ? (
                        <div className="new-spinner p-absolute">
                            <div>
                                <span className="spinner-border spinner-border-sm custom-spinner-border" />
                            </div>
                            <p className="fs-14 custom-loading-text">Loading</p>
                        </div>
                    ) : (
                        <LoginSocialMicrosoft
                            client_id={microsoftClientId}
                            redirect_uri={window.location.origin}
                            onResolve={({ provider, data }: IResolveParams) => {
                                if (provider === 'microsoft') {
                                    setLoading(provider);
                                    signIn(provider, data);
                                }
                            }}
                            onReject={(err: any) => {
                                console.log('LoginSocialMicrosoft error', err);
                            }}
                            scope="profile openid email User.Read"
                        >
                            <button type="button" className="btn login-btn microsoft-btn pointer-events-all text-start">
                                <span className="microsoft-icon">
                                    <img src={microsoftLogo} width={20} alt="Microsoft logo" />
                                </span>
                                <span className="social-btn-text">Sign up with Microsoft</span>
                            </button>
                        </LoginSocialMicrosoft>
                    )}
                </div>
            </div>
        </MediaLayout>
    );
};

export default AuthSocial;
